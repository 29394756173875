import { Injectable } from '@angular/core';
import { Device, DeviceInfo } from '@capacitor/device';
import { BehaviorSubject, Observable, Subscription, tap, throttleTime, throwError } from 'rxjs';
import { STATE, UPDATE_INTERVAL } from '../avatar.constants';
import { BatteryStatus } from '@awesome-cordova-plugins/battery-status/ngx';
import { BatteryStatusResponse } from '@awesome-cordova-plugins/battery-status';
import { catchError } from 'rxjs/operators';
import * as Sentry from '@sentry/angular';
import { PlatformService } from '../../services/platform.service';
import { ToastService } from '../../services/toast.service';
import { TranslateService } from '@ngx-translate/core';
import { ChatController } from '../../chat/chat.controller';
import { CustomBatteryInfo } from '../gmap/gmap.interfaces';

declare var navigator: Navigator;

interface BatteryManager extends EventTarget {
  readonly charging: boolean;
  readonly chargingTime: number;
  readonly dischargingTime: number;
  readonly level: number;
  onchargingchange: ((this: BatteryManager, ev: Event) => any) | null;
  onchargingtimechange: ((this: BatteryManager, ev: Event) => any) | null;
  ondischargingtimechange: ((this: BatteryManager, ev: Event) => any) | null;
  onlevelchange: ((this: BatteryManager, ev: Event) => any) | null;
}

interface Navigator extends globalThis.Navigator {
  getBattery?: () => Promise<any>;
}

@Injectable({
  providedIn: 'root'
})
export class PhoneInfoService {
  public errors: string[] = [];
  public phoneModel: string;
  public osVersion: string;
  public isWatchBattery: boolean = false;
  private avatarEventSubscription: Subscription;
  private initBattery: CustomBatteryInfo = {level: -1, isCharging: false};
  private myBatterySubject: BehaviorSubject<CustomBatteryInfo> = new BehaviorSubject(this.initBattery);
  public myBattery$: Observable<CustomBatteryInfo> = this.myBatterySubject.asObservable();
  private executorBatterySubject: BehaviorSubject<CustomBatteryInfo> = new BehaviorSubject(this.initBattery);
  public executorBattery$: Observable<CustomBatteryInfo> = this.executorBatterySubject.asObservable();
  private batteryStatusSubscription: Subscription;
  private batteryManager: BatteryManager;

  constructor(public platformService: PlatformService,
              private batteryStatus: BatteryStatus,
              private toastService: ToastService,
              private translate: TranslateService,
              private chatController: ChatController,
  ) {
    this.subscribeAvatarEvents();
  }

  ngOnDestroy(): void {
    this.avatarEventSubscription?.unsubscribe();
    this.batteryStatusSubscription?.unsubscribe();

    if (this.batteryManager) {
      this.batteryManager.onlevelchange = null;
      this.batteryManager.onchargingchange = null;
      this.batteryManager = null;
    }
  }

  public get phoneModelWithOS(): string {
    return `${this.phoneModel} (${this.osVersion})`;
  }

  public async getDeviceModel(): Promise<string> {
    const info: DeviceInfo = await Device.getInfo();
    this.phoneModel = info.name
      ? info.name.charAt(0).toUpperCase() + info.name.slice(1)
      : this.getShortModel(info.manufacturer, info.model);
    this.osVersion = this.getShortOSVersion(info.operatingSystem, info.osVersion);
    return this.phoneModel;
  }

  public async toggleBatteryChargeWatching(state: STATE): Promise<void> {
    console.log(`🟣 toggleBatteryChargeWatching state: ${state}`);

    if (this.platformService.isDevice) {
      this.toggleBatteryChargeWatchingWithDevice(state);
    } else {
      await this.toggleBatteryChargeWatchingWithBrowser(state);
    }
  }

  private subscribeAvatarEvents(): void {
    if (!this.avatarEventSubscription) {
      this.avatarEventSubscription = this.chatController.avatarEvent.subscribe(async res => {
        switch (res?.type) {
          case 'phoneInfoRequest':
            // console.log('\x1b[35m' + `Socket event 'phoneInfoRequest'` + '\x1b[0m', res.data);
            if (!res?.data?.from_id) {
              return console.error('Received incorrect socket data for phoneInfoRequest!');
            }
            await this.getDeviceModel();
            this.chatController.emitAvatarPhoneInfoResponse(
              res?.data?.from_id,
              this.myBatterySubject.value.level,
              this.myBatterySubject.value.isCharging,
              this.phoneModelWithOS
            );
            break;
          case 'phoneInfoResponse':
            // console.log('\x1b[35m' + `Socket event 'phoneInfoResponse 1'` + '\x1b[0m', res?.data);
            if (!(res?.data?.battery_level > -1) && !res?.data?.phone_model) {
              return console.error('Received incorrect socket data for phoneInfoResponse!');
            }
            this.updateExecutorBatteryInfo(res.data.battery_level, res.data?.is_charging);
            break;
        }
      });
    }
  }

  private getShortModel(manufacturer: string, model: string): string {
    const shortModel: string = model.split(' ').slice(0, 2).join(' ');
    return `${manufacturer} ${shortModel ? shortModel : 'Unknown'}`;
  }

  private getShortOSVersion(osName: string, osVersion: string): string {
    const shortVersion: RegExpMatchArray = osVersion.match(/(\d+(\.\d+)?)/);
    const capitalizedOS: string = this.capitalizeFirstLetter(osName);
    return `${capitalizedOS} ${shortVersion ? shortVersion[0] : 'Unknown'}`;
  }

  private capitalizeFirstLetter(string: string): string {
    if (string === 'ios') {
      return 'iOS';
    } else {
      return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    }
  }

  private toggleBatteryChargeWatchingWithDevice(state: STATE): void {
    switch (state) {
      case STATE.ACTIVATE:
        if (this.batteryStatusSubscription) return;

        this.batteryStatusSubscription = this.batteryStatus.onChange().pipe(
          throttleTime(UPDATE_INTERVAL.BATTERY),
          tap(async (status: BatteryStatusResponse): Promise<void> => {
            this.updateMyBatteryInfo(Math.round(status.level), status.isPlugged);
          }),
          catchError(async error => {
            await this.toastService.error(this.translate.instant('avatar.avatarService.toasts.battery.errorWatchingBattery'));
            console.error(error);
            this.errors.push(error);
            Sentry.captureException(error);
            throwError(() => new Error(error));
          })
        ).subscribe();

        this.isWatchBattery = true;

        break;
      case STATE.DEACTIVATE:
        if (!this.batteryStatusSubscription) return;

        if (this.batteryStatusSubscription) {
          this.isWatchBattery = false;
          this.batteryStatusSubscription.unsubscribe();
          this.batteryStatusSubscription = null;
        }

        break;
    }
  }

  private async toggleBatteryChargeWatchingWithBrowser(state: STATE): Promise<void> {
    switch (state) {
      case STATE.ACTIVATE:
        if (this.batteryManager) return;

        if (navigator.getBattery) {
          try {
            this.batteryManager = await navigator.getBattery();
            this.updateMyBatteryInfo(Math.round(this.batteryManager.level * 100), this.batteryManager.charging);

            this.batteryManager.onlevelchange = (): void => {
              this.updateMyBatteryInfo(Math.round(this.batteryManager.level * 100), this.batteryManager.charging);
            };

            this.batteryManager.onchargingchange = (): void => {
              this.updateMyBatteryInfo(Math.round(this.batteryManager.level * 100), this.batteryManager.charging);
            };

            this.isWatchBattery = true;
          } catch (error) {
            await this.toastService.error(this.translate.instant('avatar.avatarService.toasts.battery.errorWatchingBattery'));
            console.error(error);
            this.errors.push(error);
            Sentry.captureException(error);
          }
        } else {
          console.log('Battery Status API is not supported on this device.');
        }

        break;
      case STATE.DEACTIVATE:
        if (!this.batteryManager) return;

        if (this.batteryManager) {
          this.batteryManager.onlevelchange = null;
          this.batteryManager.onchargingchange = null;
          this.batteryManager = null;
          this.isWatchBattery = false;
        }

        break;
    }
  }

  private updateExecutorBatteryInfo(level: number, isCharging: boolean): void {
    this.executorBatterySubject.next({level, isCharging});
  }

  private updateMyBatteryInfo(level: number, isCharging: boolean): void {
    this.myBatterySubject.next({level, isCharging});
  }
}
